import React, { useEffect, useState } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import logo from "../../assets/images/Logo_Noir-1.png";
import defaultLogoUser from "../../assets/images/user.png";
import { useSelector, useDispatch } from "react-redux";

import { logout } from "../../redux/actions/userActions";
import "./style.css";

const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const userLogin = useSelector((state) => state.userLogin);
  const [isOnlyTrunk, setIsOnlyTrunk] = useState(true);
  const { userInfo } = userLogin;
  //verification si c'est un client trunk
  useEffect(()=>{
    if(userInfo?.data?.user?.isOnlyTrunk){
     navigate('/trunk')
    }
   },[])
  useEffect(() => {
    if (!userInfo) {
      navigate("/login");
    } else {
      if (userInfo?.data?.user?.isOnlyTrunk) {
        setIsOnlyTrunk(true);
      } else {
        setIsOnlyTrunk(false);
      }
    }
  }, [userInfo, navigate]);

  const handleLogout = () => {
    dispatch(logout());
    navigate("/login");
  };

  return (
    <>
      <header style={{ marginBottom: "20px" }}>
        <nav
          class="navbar navbar-expand-lg bg-body-tertiary"
          style={{ padding: "0" }}
        >
          <div class="container-fluid" style={{ backgroundColor: "white" }}>
            <a
              class="navbar-brand"
              style={{
                paddingTop: "10px",
                color: "white",
                fontWeight: "600",
                border: "none",
              }}
            >
              <img
                src={logo}
                alt=""
                className="logoNav"
                style={{ height: "50px" }}
              />
            </a>
            <button
              class="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <ul class="navbar-nav me-auto mb-2 mb-lg-0"></ul>
              <form
                class="d-flex"
                style={{ justifyContent: "center", alignItems: "center" }}
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                <div style={{ marginLeft: "20px" }}>
                  <img
                    src={defaultLogoUser}
                    style={{
                      width: "30px",
                      height: "30px",
                      borderRadius: "50%",
                      border: "solid gray 1px",
                    }}
                  />
                  <span
                    style={{
                      color: "#334155",
                      marginLeft: "10px",
                      fontWeight: "500",
                    }}
                  >
                    {userInfo?.data?.user?.nom ? userInfo.data.user.nom : ""}{" "}
                    {userInfo?.data?.user?.prenom
                      ? userInfo?.data.user.prenom
                      : ""}
                  </span>
                </div>
                <i
                  class="bi bi-box-arrow-right"
                  style={{
                    color: "#6cc070",
                    marginLeft: "20px",
                    cursor: "pointer",
                    marginRight: "10px",
                  }}
                  data-bs-toggle="modal"
                  data-bs-target="#myModal01"
                ></i>
                <div class="modal" id="myModal01" style={{ zIndex: "9999" }}>
                  <div class="modal-dialog">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h4 class="modal-title">Déconnexion</h4>
                        <button
                          type="button"
                          class="btn-close"
                          data-bs-dismiss="modal"
                        ></button>
                      </div>

                      <div class="modal-body">
                        Voulez-vous vraiment vous déconnecter ?
                      </div>

                      <div class="modal-footer">
                        <button
                          type="button"
                          class="btn btn-danger"
                          data-bs-dismiss="modal"
                        >
                          Annuler
                        </button>
                        <button
                          type="button"
                          class="btn"
                          style={{ color: "white", backgroundColor: "#6cc070" }}
                          data-bs-dismiss="modal"
                          onClick={(ev) => {
                            localStorage.clear();
                            handleLogout();
                          }}
                        >
                          Confirmer
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </nav>
      </header>
      <button
        className="btn"
        style={{
          position: "fixed",
          top: "66px",
          left: "-4px",
          backgroundColor: "#61CE70",
          color: "white",
          padding: "6px 6px",
          zIndex: "99999999999999999999",
        }}
        data-bs-toggle="offcanvas"
        data-bs-target="#userHeader"
      >
        <i
          class="bi bi-list"
          style={{ fontSize: "16px", fontWeight: "600" }}
        ></i>
      </button>
      <div
        class="offcanvas offcanvas-start"
        id="userHeader"
        style={{ zIndex: "999999999999999999999" }}
      >
        <div class="offcanvas-header">
          <img
            src={logo}
            alt=""
            className="logoNav"
            style={{ height: "50px" }}
          />
          <button
            type="button"
            class="btn-close text-reset"
            data-bs-dismiss="offcanvas"
          ></button>
        </div>
        <div
          class="offcanvas-body"
          style={{ padding: "0", backgroundColor: " rgb(241, 241, 241)" }}
        >
          {!isOnlyTrunk && (
            <>
              <div
                style={{
                  cursor: "pointer",
                  width: "100%",
                  textAlign: "left",
                  padding: "10px 5px",
                  backgroundColor:
                    location.pathname === "/"
                      ? "lightgray"
                      : "rgb(241, 241, 241)",
                }}
                onClick={(ev) => navigate("/")}
                data-bs-dismiss="offcanvas"
              >
                <span style={{ fontSize: "18px", fontWeight: "bold" }}>
                  Accès
                </span>
              </div>
              <div
                style={{
                  cursor: "pointer",
                  width: "100%",
                  textAlign: "left",
                  padding: "10px 5px",
                  backgroundColor:
                    location.pathname === "/demandes"
                      ? "lightgray"
                      : "rgb(241, 241, 241)",
                }}
                onClick={(ev) => navigate("/demandes")}
                data-bs-dismiss="offcanvas"
              >
                <span style={{ fontSize: "18px", fontWeight: "bold" }}>
                  Demandes
                </span>
              </div>
              <div
                style={{
                  cursor: "pointer",
                  width: "100%",
                  textAlign: "left",
                  padding: "10px 5px",
                  backgroundColor:
                    location.pathname === "/numero"
                      ? "lightgray"
                      : "rgb(241, 241, 241)",
                }}
                onClick={(ev) => navigate("/numero")}
                data-bs-dismiss="offcanvas"
              >
                <span style={{ fontSize: "18px", fontWeight: "bold" }}>
                  Numéros
                </span>
              </div>
              <div
                style={{
                  cursor: "pointer",
                  width: "100%",
                  textAlign: "left",
                  padding: "10px 5px",
                  backgroundColor:
                    location.pathname === "/campagne"
                      ? "lightgray"
                      : "rgb(241, 241, 241)",
                }}
                onClick={(ev) => navigate("/campagne")}
                data-bs-dismiss="offcanvas"
              >
                <span style={{ fontSize: "18px", fontWeight: "bold" }}>
                  Campagnes
                </span>
              </div>
            </>
          )}
          <div
            style={{
              cursor: "pointer",
              width: "100%",
              textAlign: "left",
              padding: "10px 5px",
              backgroundColor:
                location.pathname === "/Trunk"
                  ? "lightgray"
                  : "rgb(241, 241, 241)",
            }}
            onClick={(ev) => navigate("/Trunk")}
            data-bs-dismiss="offcanvas"
          >
            <span style={{ fontSize: "18px", fontWeight: "bold" }}>Trunk</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
