import React, { useEffect, useState } from "react";
import { Col, Container, Dropdown, Row, Table } from "react-bootstrap";
import Card from "../../components/Card/Card";
import { getInfoAllAgentAndCampagne } from "../../redux/actions/compagneActions";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import Message from "../../components/Message";
import Loader from "../../components/Loader";
import { useToasts } from "react-toast-notifications";
import { Link } from "react-router-dom";
import "./style.css";
import { ReactTabulator } from "react-tabulator";
import ModalCreateAgent from "../Agent/ModalCreateAgent";
import { searchOptimizedData } from "../../utils/recherche";
const Home = () => {
  const { addToast } = useToasts();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const compte = useSelector((state) => state.compteInfo);
  const { loading, error, compteInfo } = compte;
  const [showAlertAssignRequise, setShowAlertAssignRequise] = useState(false);
  const [dataRequest, setDataRequest] = useState();
  const fieldsToFilter = [
    "prenom",
    "nbCampagne",
    "nbNumero",
    "nbAppel",
    "nbHeureAppel",
    "createdAt",
  ];
  if (!userInfo?.token) {
    navigate("/login");
  }
  console.log(userInfo);
  if(userInfo?.data?.user?.isOnlyTrunk){
    navigate('/trunk')
  }
  
  //simple input react tabulator
  const simpleInput = (cell) => {
    const input = document.createElement("input");
    input.type = "text";
    input.style =
      "width:100%;border:none;padding:11px 22px;font-size:16px;font-weight:600;color:#334155;background-color:white";
    input.defaultValue = cell.getValue();
    input.disabled = true;
    const container = document.createElement("div");
    container.style = "display:flex;align-items:center;width:100%";
    container.appendChild(input);
    return container;
  };
  //boutton parametrage
  const buttonAssignment = (cell) => {
    const data = cell.getData();
    const btn = document.createElement("button");
    btn.className = "btn";
    btn.style = "background-color:#61CE70;color:white";
    const icon = document.createElement("icon");
    icon.className = "bi bi-plus";
    btn.appendChild(icon);
    btn.addEventListener("click", function (ev) {
      navigate(`/agent/${data._id}?from=/`);
    });
    const container = document.createElement("div");
    container.appendChild(btn);
    return container;
  };
  //Colonne react tabulator numero
  const colonneAgent = [
    { title: "Nom", minWidth: 150, field: "prenom", formatter: simpleInput },
    {
      title: "Nb appel",
      minWidth: 120,
      field: "nbAppel",
      formatter: simpleInput,
    },
    {
      title: "Nb campagne",
      minWidth: 120,
      field: "nbCampagne",
      formatter: simpleInput,
    },
    {
      title: "Numéros personnel",
      minWidth: 120,
      field: "nbNumero",
      formatter: simpleInput,
    },
    {
      title: "Nb Heure",
      minWidth: 120,
      field: "nbHeureAppel",
      formatter: simpleInput,
    },
    { title: "Plus", width: 100, field: "", formatter: buttonAssignment },
  ];

  //verification si c'est un client trunk
  useEffect(()=>{
    console.log(userInfo);
   if(userInfo?.data?.user?.isOnlyTrunk){
    navigate('/trunk')
   }
  },[])
  //recuperation information global du compte
  useEffect(() => {
    dispatch(getInfoAllAgentAndCampagne());
  }, []);

  //gestion du champ de recherche
  useEffect(() => {
    if (compteInfo && compteInfo.agentsWithDetails) {
      setDataRequest(compteInfo.agentsWithDetails);
    }
  }, [compteInfo]);

  //notification d'erreur
  useEffect(() => {
    if (error && userInfo?.token) {
      addToast(error, {
        appearance: "error",
        autoDismiss: true,
      });
    }
  }, [error]);
  return (
    <>
      {showAlertAssignRequise && (
        <>
          <div class="alert alert-danger alert-dismissible">
            <button
              type="button"
              class="btn-close"
              onClick={(ev) =>
                setShowAlertAssignRequise(!showAlertAssignRequise)
              }
            ></button>
            <strong>Sélection d'agent requise!</strong>Au moins un agent
          </div>
        </>
      )}
      {loading ? (
        <Loader />
      ) : (
        <Container>
          {/*  {error && (
            <Row>
              <Message variant="danger">{error}</Message>
            </Row>
          )} */}

          <Row className="d-flex flex-wrap justify-content-between align-items-center">
            <Col className="col-12" style={{ marginLeft: "5px" }}>
              <h5 className="current-route text-capitalize">/ Accès /</h5>
            </Col>
          </Row>

          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              width: "100%",
              justifyContent: "space-between",
              marginBottom: "20px",
            }}
          >
            {/*  <Link to={"/budget?from=/"}>
              <Card
                cardTitle="Alertes"
                cardValeur={
                  compteInfo && compteInfo.stats.alertes
                    ? compteInfo.stats.alertes
                    : "0"
                }
              />
            </Link> */}
            <Card
              cardTitle="Agents"
              cardValeur={
                compteInfo && compteInfo.stats?.agentsCounts
                  ? compteInfo.stats.agentsCounts.toString()
                  : "0"
              }
            />
            <Card
              cardTitle="Durée appel"
              cardValeur={
                compteInfo && compteInfo.stats?.nbHeureAppel
                  ? compteInfo.stats.nbHeureAppel
                  : "0"
              }
            />
            <Card
              cardTitle="Nb Appels"
              cardValeur={
                compteInfo && compteInfo.stats?.nbAppel
                  ? `${compteInfo.stats.nbAppel}`
                  : "0"
              }
            />
          </div>
          <Row className="tabHistorique">
            <div
              style={{
                width: "100%",
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                padding: "0px 0px 0px 40px",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <span className="titreTabulator">
                  Liste des agents -{" "}
                  {dataRequest?.length ? dataRequest.length : "0"} agent(s)
                </span>
              </div>
              <div
                className="champRecherche"
                style={{ display: "flex", alignItems: "center" }}
              >
                <span
                  style={{
                    padding: "8px 12px 8px 12px",
                    backgroundColor: "rgba(226, 232, 240, 0.6)",
                    borderRadius: "8px",
                    height: "40px",
                    display: "flex",
                  }}
                >
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="#818CF8"
                      style={{ width: "18px", height: "18px" }}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                      />
                    </svg>
                  </span>
                  <input
                    type="text"
                    className="rechercheTabulator"
                    placeholder="Recherche"
                    onChange={(ev) => {
                      if (compteInfo && compteInfo.agentsWithDetails) {
                        const dataSearch = searchOptimizedData(
                          ev.target.value,
                          compteInfo.agentsWithDetails,
                          fieldsToFilter
                        );
                        setDataRequest(dataSearch);
                      }
                    }}
                  />
                </span>
              </div>
            </div>
            <ReactTabulator
              data={dataRequest ? dataRequest : []}
              columns={colonneAgent}
              layout={"fitColumns"}
              options={{
                locale: true,
                pagination: "local",
                paginationSize: 5,
                langs: {
                  fr: {
                    pagination: {
                      first: "Premier",
                      first_title: "Première",
                      last: "Dernier",
                      last_title: "Dernière",
                      prev: "Précédent",
                      prev_title: "Précédente",
                      next: "Suivant",
                      next_title: "Page suivante",
                      page_size: "Taille de page",
                      page_title: "Afficher la page",
                      all: "Tous",
                    },
                  },
                },
              }}
            />
          </Row>
        </Container>
      )}

      <ModalCreateAgent />
    </>
  );
};

export default Home;
